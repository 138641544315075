<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">AGREGAR SECTOR</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-10">
            <div class="field control">
              <label class="label">Sector</label>
              <input
                class="input"
                type="text"
                placeholder="Sector"
                name="sectorName"
                v-model="sectorName"
                v-validate="'required'"
                data-vv-as="del sector"
              >
              <span v-show="vverrors.has('sectorName')" class="help is-danger">{{ vverrors.first('sectorName') }}</span>
            </div>
          </div>
          <div class="column is-10">
          <div class="is-flex is-align-items-center">
            <label class="label mr-5 mb-0">Estatus</label>
            <span>
              <input
                id="switchStatus"
                type="checkbox"
                name="switchStatusStatus"
                class="switch is-rounded is-small"
                v-model="status"
                :checked="status ? 'checked' : ''"
              >
              <label for="switchStatus"></label>
            </span>
          </div>
        </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button button_micro_dark_outlined" @click="$emit('close-modal')" :disabled="loading">CANCELAR</button>
        <button
          class="button button_micro_dark"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="setDataCreateAction"
        >
          AGREGAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddSectors',
  data () {
    return {
      status: true,
      sectorName: null
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async setDataCreateAction () {
      if (await this.$validator.validateAll()) {
        const dataCreate = {
          sectorName: this.sectorName,
          active: this.status
        }
        this.$emit('set-data-create', dataCreate)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
